import React, { useCallback, useMemo } from "react";
import { createPortal } from "react-dom";
import { DEFAULT_ADDRESS, getKaikasAddress } from "./kaikas";

export const ADDRESS_KEY = "pixel-ground-address";

const WalletInfo = ({ address, setAddress }) => {
  const isValidAddress = useMemo(
    () => !!address && address !== "" && address !== DEFAULT_ADDRESS,
    [address]
  );
  const rootElement = useMemo(
    () => document.getElementById("header-wallet"),
    []
  );

  const onClickWallet = useCallback(async () => {
    // already logged-in
    if (isValidAddress) {
      if (window.confirm("로그아웃 하시겠습니까?")) {
        sessionStorage.removeItem(ADDRESS_KEY);
        setAddress("");
      }
    }
    // log in
    else {
      getKaikasAddress((kaikasAddress) => {
        sessionStorage.setItem(ADDRESS_KEY, kaikasAddress);
        setAddress(kaikasAddress);
      });
    }
  }, [isValidAddress, setAddress]);

  // Error, show default button
  if (rootElement === null || rootElement === undefined) {
    return (
      // eslint-disable-next-line
      <a onClick={onClickWallet}>
        <img src="_img/comm/icon_klay.png" alt="connect wallet" /> Connect
        Wallet OFF
      </a>
    );
  }

  if (isValidAddress) {
    return createPortal(
      // eslint-disable-next-line
      <a onClick={onClickWallet}>
        <img src="_img/comm/icon_klay.png" alt="connect wallet" />
        {address.slice(0, 7)}...{address.slice(-7)}
      </a>,
      rootElement
    );
  }

  // if not logged-in, show default(login) button
  return createPortal(
    // eslint-disable-next-line
    <a onClick={onClickWallet}>
      <img src="_img/comm/icon_klay.png" alt="connect wallet" /> Connect Wallet
      OFF
    </a>,
    rootElement
  );
};

export default React.memo(WalletInfo);
