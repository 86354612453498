import dotenv from "dotenv";
dotenv.config();

const config = {
  CHAIN_ID: process.env.REACT_APP_CHAIN_ID,
  KAS_KEY_ID: process.env.REACT_APP_KAS_KEY_ID,
  KAS_SECRET_KEY: process.env.REACT_APP_KAS_SECRET_KEY,

  REVEAL_ADDRESS: "0x652035210613F89947044770cf10f1046722d132",
  PIXEL_ADDRESS: "0x24bd51a7e8b4a77201071cac1118331da49a1a38",
};

export default config;
