import React, { useCallback, useEffect, useState } from "react";
import { getIsApproved, reveal, setApproveAll } from "./kaikas";
import WalletInfo from "./WalletInfo";

function App() {
  const [address, setAddress] = useState();
  const [isApproved, setIsApproved] = useState();
  const [tokenId, setTokenId] = useState();

  useEffect(() => {
    if (!!address && address !== "") {
      const getIsAddressApproved = async () => {
        const approved = await getIsApproved(address);
        setIsApproved(approved);
      };
      getIsAddressApproved();
    }
  }, [address]);

  const onChangeTokenId = useCallback(
    (event) => {
      if (isNaN(event.target.value)) return;
      if (event.target.value.length > 5) return;
      setTokenId(Number(event.target.value));
    },
    [setTokenId]
  );

  const onClickReveal = useCallback(async () => {
    if (!address) {
      alert("지갑을 먼저 연결해 주세요.");
      return;
    }
    if (tokenId === undefined || tokenId < 0 || tokenId > 9999) {
      alert("정확한 토큰 번호를 입력해 주세요.");
      return;
    }
    // 이미 권한이 승인됨. 바로 리빌
    if (isApproved) {
      reveal({
        address,
        tokenId,
        callback: () => alert(`#${tokenId} 토큰을 성공적으로 리빌했습니다!`),
      });
    }
    // 권한 승인 이후 리빌
    else {
      setApproveAll({
        address,
        callback: () => {
          reveal({
            address,
            tokenId,
            callback: () =>
              alert(`#${tokenId} 토큰을 성공적으로 리빌했습니다!`),
          });
        },
      });
    }
  }, [address, tokenId, isApproved]);

  return (
    <div className="App">
      <WalletInfo address={address} setAddress={setAddress} />

      <div class="btn_box">
        <button onClick={onClickReveal}>exchange!</button>
      </div>

      <div class="number">
        <div>
          <label for="my_number">Number</label>
        </div>
        <div>
          <input
            type="text"
            id="my_number"
            value={tokenId}
            onChange={onChangeTokenId}
          />
        </div>
      </div>
    </div>
  );
}

export default App;
