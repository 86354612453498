import Caver from "caver-js";

import PIXEL_ABI_JSON from "./abi/pixel.abi.json";
import REVEAL_ABI_JSON from "./abi/reveal.abi.json";
import APPROVE_ABI_JSON from "./abi/setApproveAll.abi.json";
import config from "./config";

const { PIXEL_ADDRESS, REVEAL_ADDRESS } = config;

export const KAIKAS_PROVIDER = window.klaytn;
export const CAVER = new Caver(KAIKAS_PROVIDER);
const PIXEL_CONTRACT = new CAVER.contract(PIXEL_ABI_JSON, PIXEL_ADDRESS);

export const DEFAULT_ADDRESS = "0x0000000000000000000000000000000000000000";

const isValidAddress = (address) => {
  return (
    !!address &&
    address !== "" &&
    address !== DEFAULT_ADDRESS &&
    CAVER.utils.isAddress(address)
  );
};
const isValidKaikasAccounts = (accounts) => {
  return !!accounts && accounts.length > 0 && isValidAddress(accounts[0]);
};

export const getKaikasAddress = (callback) => {
  if (!KAIKAS_PROVIDER) {
    alert("카이카스를 설치해 주세요. 카이카스는 PC 웹에서만 사용 가능합니다.");
    window.open(
      "https://chrome.google.com/webstore/detail/kaikas/jblndlipeogpafnldhgmapagcccfchpi"
    );
    return;
  }
  KAIKAS_PROVIDER.enable().then((accounts) => {
    // 1. 현재 연결된 지갑 주소 체크
    if (!isValidKaikasAccounts(accounts)) {
      alert("올바르지 않은 계정 정보입니다. 잠시 후 다시 시도해 주세요.");
      return;
    }
    // 2. 현재 연결된 지갑 주소 받아오기
    callback(accounts[0]);
  });
};

export const getIsApproved = async (address) => {
  try {
    if (!PIXEL_CONTRACT) {
      alert("잠시 후 다시 시도해 주세요.");
      return;
    }
    const isApprovedForAll = await PIXEL_CONTRACT.methods
      .isApprovedForAll(address, REVEAL_ADDRESS)
      .call();
    return isApprovedForAll;
  } catch (error) {
    console.log(error);
  }
};

export const setApproveAll = async ({ address, callback }) => {
  try {
    if (!KAIKAS_PROVIDER || !CAVER) {
      alert(
        "카이카스를 설치해 주세요. 카이카스는 PC 웹에서만 사용 가능합니다."
      );
      window.open(
        "https://chrome.google.com/webstore/detail/kaikas/jblndlipeogpafnldhgmapagcccfchpi"
      );
      return;
    }
    if (!isValidAddress(address)) {
      alert("지갑을 연결해 주세요.");
      return;
    }
    const ENCODED_FUNCTION = CAVER.klay.abi.encodeFunctionCall(
      APPROVE_ABI_JSON,
      [REVEAL_ADDRESS, true]
    );

    const response = await CAVER.klay.sendTransaction({
      type: "SMART_CONTRACT_EXECUTION",
      from: address,
      to: PIXEL_ADDRESS,
      data: ENCODED_FUNCTION,
      gas: "100000",
    });

    if (
      response.status === "0x1" ||
      response.status === "true" ||
      response.status === true ||
      response.status === 1
    ) {
      if (callback) {
        callback();
      }
    } else {
      // fail
      alert("권한 승인에 실패했습니다. 잠시 후 다시 시도해 주세요.");
    }

    // Refresh after mint
  } catch (error) {
    if (error.message.includes("User denied transaction")) {
      alert("요청을 취소하셨습니다.");
      return;
    } else if (
      error.message.includes("execution reverted") ||
      error.message.includes("Execution reverted")
    ) {
      alert("트랜잭션이 거부되었습니다.");
      return;
    } else if (error.message.includes('Invalid "from" address')) {
      alert("현재 연결한 지갑 정보를 확인해 주세요.");
      window.location.reload();
      return;
    }
    alert("권한 승인에 실패했습니다.");
    console.log(error);
  }
};

export const reveal = async ({ address, tokenId, callback }) => {
  if (!KAIKAS_PROVIDER || !CAVER) {
    alert("카이카스를 설치해 주세요. 카이카스는 PC 웹에서만 사용 가능합니다.");
    window.open(
      "https://chrome.google.com/webstore/detail/kaikas/jblndlipeogpafnldhgmapagcccfchpi"
    );
    return;
  }
  if (!isValidAddress(address)) {
    alert("지갑을 연결해 주세요.");
    return;
  }
  try {
    const ENCODED_FUNCTION = CAVER.klay.abi.encodeFunctionCall(
      REVEAL_ABI_JSON,
      [tokenId]
    );

    const response = await CAVER.klay.sendTransaction({
      type: "SMART_CONTRACT_EXECUTION",
      from: address,
      to: REVEAL_ADDRESS,
      data: ENCODED_FUNCTION,
      gas: "1000000",
    });

    if (
      response.status === "0x1" ||
      response.status === "true" ||
      response.status === true ||
      response.status === 1
    ) {
      if (callback) {
        callback();
      }
    } else {
      // fail
      alert("민팅에 실패했습니다. 카이카스 지갑을 확인해 주세요.");
    }

    // Refresh after mint
  } catch (error) {
    if (error.message.includes("User denied transaction")) {
      alert("요청을 취소하셨습니다.");
      return;
    } else if (
      error.message.includes("execution reverted") ||
      error.message.includes("Execution reverted")
    ) {
      alert("트랜잭션이 거부되었습니다.");
      return;
    } else if (error.message.includes('Invalid "from" address')) {
      alert("현재 연결한 지갑 정보를 확인해 주세요.");
      window.location.reload();
      return;
    }
    alert("리빌에 실패했습니다. 카이카스 지갑을 확인해 주세요.");
    console.log(error);
  }
};
